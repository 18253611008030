import type {
  TStrapiRequest,
  TStrapiGetResponse,
  TStrapiListResponse,
  TStrapiRequestQuery
} from '@tl-strapi/types/TRequest'
import type { FetchRequest, FetchResponse } from 'ofetch'
import type { TJobBoardArticle } from '@tl-strapi/types/TJobBoardArticle'
import type { $Img } from '@nuxt/image/dist/types'
import { useDevicePixelRatio } from '@vueuse/core'
export class StrapiError extends Error {
  statusCode: number
  name = 'StrapiError'

  constructor(message: string, statusCode: number) {
    super(message)
    this.statusCode = statusCode
  }
}

export function getCategoriesOrderedByIdsRequest(page: number, pageSize: number): TStrapiRequest {
  return {
    pathname: 'job-board-categories',
    query: {
      locale: 'zh-Hant',
      publicationState: 'live',
      sort: 'id:asc',
      'pagination[page]': page,
      'pagination[pageSize]': pageSize,
      'pagination[withCount]': true
    }
  }
}

export function getCategoryByIdRequest(categoryId: string | number): TStrapiRequest {
  return {
    pathname: 'job-board-categories/' + categoryId,
    query: {}
  }
}

export function getLatestArticlesRequest(
  page: number,
  pageSize: number,
  ignoreContentFarmCat: boolean
): TStrapiRequest {
  const requestPayload: TStrapiRequestQuery = {
    populate: ['Author', 'Category', 'CoverImage', 'EnabledRegions'],
    locale: 'zh-Hant',
    publicationState: 'live',
    sort: 'publishedAt:desc',
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
    'pagination[withCount]': true
  }
  if (ignoreContentFarmCat) {
    requestPayload['filters[Category][id][$notIn]'] = [19, 20]
  }
  return {
    pathname: 'job-board-articles',
    query: requestPayload
  }
}

export function getLatestArticlesOfACategoryRequest(
  categoryId: string | number,
  page: number,
  pageSize: number
): TStrapiRequest {
  const requestPayload: TStrapiRequestQuery = {
    populate: ['Author', 'Category', 'CoverImage', 'EnabledRegions'],
    locale: 'zh-Hant',
    publicationState: 'live',
    sort: 'publishedAt:desc',
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
    'pagination[withCount]': true,
    'filters[Category][id]': categoryId
  }
  return {
    pathname: 'job-board-articles',
    query: requestPayload
  }
}

export function getArticleByIdRequest(id: string | number) {
  return {
    pathname: `job-board-articles/${id}`,
    query: {
      populate: ['Author', 'Category', 'CoverImage']
    }
  }
}

export function useStrapiListOnce<T>(
  endpoint: string,
  query: TStrapiRequestQuery | Ref<TStrapiRequestQuery>,
  fetchOptions?: Object
): Promise<TStrapiListResponse<T>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const defaultQuery = getStrapiDefaultFilters(
    runtimeConfig.public.environmentName,
    endpoint,
    regionState.value
  )

  if (isRef(query)) {
    Object.entries(defaultQuery).forEach(([k, v]: any[]) => {
      query.value[k] = v
    })
  } else {
    Object.entries(defaultQuery).forEach(([k, v]: any[]) => {
      query[k] = v
    })
  }

  const baseURL = runtimeConfig.public.strapiBaseProxyUrl
  return $fetch(endpoint, {
    baseURL,
    method: 'GET',
    query,
    ...fetchOptions
  })
}

function getAsyncGetHandler<
  TRes extends TStrapiGetResponse<unknown> | TStrapiListResponse<unknown>
>(
  baseURL: string,
  path: string,
  query: TStrapiRequestQuery | Ref<TStrapiRequestQuery>,
  oFetchOptions: Object | undefined,
  $sentryCaptureException: (error: any) => void
) {
  return async function fetchHandler(nuxtApp: any): Promise<TRes> {
    const startTime = performance.now()
    // Client side caching
    if (nuxtApp && process.client) {
      const cacheKey = await getSHA256Hash(`strapi-${path}--${JSON.stringify(query)}`)
      const { $strapiGetCache } = nuxtApp
      if ($strapiGetCache.has(cacheKey)) {
        // console.log(`Cache hit for ${cacheKey}, took ${performance.now() - startTime} ms.`)
        return $strapiGetCache.get(cacheKey)
      } else {
        // console.log(`Cache miss for ${cacheKey}`)
      }
    }
    const response: any = await $fetch(path, {
      baseURL,
      method: 'GET',
      query,
      onResponse() {
        const endTime = performance.now()
        console.log(`${path} took ${endTime - startTime} ms.`)
      },
      onResponseError({
        request,
        response
      }: {
        request: FetchRequest
        response: FetchResponse<{
          error: {
            message: string
          }
        }>
      }) {
        const endTime = performance.now()
        console.log(`${path} took ${endTime - startTime} ms.`)
        // Capture meaningful strapi errors
        const resErrorMsg = response._data?.error.message || ''
        const errorMsg = `${response.status} - ${resErrorMsg} for request ${request}`
        console.log('Error', errorMsg)
        $sentryCaptureException(new StrapiError(errorMsg, response.status))
      },
      ...oFetchOptions
    })
    if (nuxtApp && process.client && response && response.data) {
      const { $strapiGetCache } = nuxtApp
      const cacheKey = await getSHA256Hash(`strapi-${path}--${JSON.stringify(query)}`)
      $strapiGetCache.set(cacheKey, response)
    }
    return response
  }
}

export function useStrapiList<T>(
  endpoint: string,
  query: TStrapiRequestQuery | Ref<TStrapiRequestQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
): ReturnType<typeof useAsyncData<TStrapiListResponse<T> | null>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const { $sentryCaptureException } = useNuxtApp()

  const defaultQuery = getStrapiDefaultFilters(
    runtimeConfig.public.environmentName,
    endpoint,
    regionState.value
  )
  if (isRef(query)) {
    Object.entries(defaultQuery).forEach(([k, v]: any[]) => {
      query.value[k] = v
    })
  } else {
    Object.entries(defaultQuery).forEach(([k, v]: any[]) => {
      query[k] = v
    })
  }

  const baseURL = runtimeConfig.public.strapiBaseProxyUrl
  const fetchHandler = getAsyncGetHandler<TStrapiListResponse<T>>(
    baseURL,
    endpoint,
    query,
    oFetchOptions,
    $sentryCaptureException
  )
  return useAsyncData<TStrapiListResponse<T>>(
    `strapi-${endpoint}--${JSON.stringify(query)}`,
    fetchHandler,
    {
      // only watch if query is a ref
      watch: isRef(query) || isReactive(query) ? [query] : undefined,
      ...asyncDataOptions
    }
  )
}

export function useStrapiGet<T>(
  endpoint: string,
  query: TStrapiRequestQuery | Ref<TStrapiRequestQuery>,
  asyncDataOptions?: Object,
  oFetchOptions?: Object
): ReturnType<typeof useAsyncData<TStrapiGetResponse<T> | null>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const { $sentryCaptureException } = useNuxtApp()

  const defaultQuery = getStrapiDefaultFilters(
    runtimeConfig.public.environmentName,
    endpoint,
    regionState.value
  )

  if (isRef(query)) {
    Object.entries(defaultQuery).forEach(([k, v]: any[]) => {
      query.value[k] = v
    })
  } else {
    Object.entries(defaultQuery).forEach(([k, v]: any[]) => {
      query[k] = v
    })
  }
  const baseURL = runtimeConfig.public.strapiBaseProxyUrl
  const fetchHandler = getAsyncGetHandler<TStrapiGetResponse<T>>(
    baseURL,
    endpoint,
    query,
    oFetchOptions,
    $sentryCaptureException
  )

  return useAsyncData<TStrapiGetResponse<T>>(
    `strapi-${endpoint}--${JSON.stringify(query)}`,
    fetchHandler,
    {
      // only watch if query is a ref
      watch: isRef(query) || isReactive(query) ? [query] : undefined,
      ...asyncDataOptions
    }
  )
}

export function useArticleCoverImageOption(
  article: TJobBoardArticle,
  targetWidth: number,
  devicePixelRatio: number
): any {
  const width = article.attributes.CoverImage?.data.attributes.width
  const height = article.attributes.CoverImage?.data.attributes.height
  if (!width || !height) {
    return undefined
  }
  const hwRatio = height / width
  const targetHeight = Math.round(hwRatio * targetWidth)
  return {
    position: 'top',
    format: 'webp',
    quality: 50,
    fit: 'cover',
    width: targetWidth * devicePixelRatio,
    height: targetHeight * devicePixelRatio
  }
}

export function useArticleCoverImage(
  img: $Img,
  article: TJobBoardArticle,
  targetWidth: number,
  devicePixelRatio: number
) {
  let url = article.attributes.CoverImage?.data?.attributes.url
  const width = article.attributes.CoverImage?.data?.attributes.width
  const height = article.attributes.CoverImage?.data?.attributes.height
  if (!url || !width || !height) {
    return undefined
  }
  url = toS3AccelerateUrl(url)
  const imageOptions = useArticleCoverImageOption(article, targetWidth, devicePixelRatio)
  const src = img(url as string, imageOptions)
  return src
}

const prefetched = new Set()
const breakpoint2ArticleImgWidth: Record<string, number> = {
  ssm: 674,
  sm: 674,
  md: 768,
  lg: 930,
  xl: 1186,
  '2xl': 1186
}

export function usePrefetchArticleImage(article: TJobBoardArticle) {
  const viewport = useViewport()
  const { pixelRatio } = useDevicePixelRatio()
  const breakpoint = viewport.breakpoint.value
  if (prefetched.has(`${article.id}-${breakpoint}`)) {
    return
  }
  prefetched.add(`${article.id}-${breakpoint}`)
  // prefetch the data
  usePreloadedArticle().value = article
  const img = useImage()
  const targetWidth = breakpoint2ArticleImgWidth[breakpoint]

  const src = useArticleCoverImage(img, article, targetWidth, pixelRatio.value)
  if (src) {
    const img = new Image()
    img.loading = 'eager'
    img.decoding = 'sync'
    img.src = src
  }
}
