import type { TArticle } from '@tl-ui/components/ArticleCard.vue'
import type { TJobBoardArticle } from '@tl-strapi/types/TJobBoardArticle'
import { markdownToTxt } from 'markdown-to-txt'
import { toTitleSlug } from './slug'

export function toArticlePublishedDate(article: TJobBoardArticle): string {
  const today = new Date()
  const articleDate = new Date(
    article.attributes.Date || article.attributes.publishedAt || article.attributes.createdAt
  )

  if (!articleDate) {
    // Today
    return today.toISOString()
  }
  if (article.attributes.AutoDate) {
    // Generate a date candidate that is within the same month
    const dateCandidate = new Date(articleDate)
    dateCandidate.setMonth(today.getMonth())
    if (dateCandidate > today) {
      // If the date candidate is in the future, it's probably from last month
      dateCandidate.setMonth(dateCandidate.getMonth() - 1)
    }
    return dateCandidate.toISOString()
  }
  // The actual date
  return articleDate.toISOString()
}

export function toArticleDescription(article: TJobBoardArticle) {
  if (article.attributes.Description) {
    return article.attributes.Description
  }
  if (article.attributes.Content) {
    return markdownToTxt(article.attributes.Content).slice(0, 100) + '...'
  }
}

export function toArticleTags(article: TJobBoardArticle) {
  const categoryTitle = article.attributes.Category?.data?.attributes.Title
  const tags = []

  if (categoryTitle) {
    tags.push({
      title: categoryTitle
    })
  }
  if (article.attributes.Tags) {
    article.attributes.Tags.data.forEach((tag) => {
      tags.push({
        title: tag.attributes.Title
      })
    })
  }
  return tags
}

export function strapiArticlesToTArticles(articles: TJobBoardArticle[]): TArticle[] {
  if (!articles) return []
  return articles.map((article: any) => ({
    id: article.id,
    to: `/articles/${article.id}---${toTitleSlug(article.attributes.URLTextSlug)}`,
    title: article.attributes.Title,
    description: toArticleDescription(article),
    publishedDate: toArticlePublishedDate(article),
    tags: toArticleTags(article),
    thumbnail:
      article.attributes.CoverImage && article.attributes.CoverImage.data
        ? {
            url: article.attributes.CoverImage.data.attributes.formats.thumbnail.url,
            width: article.attributes.CoverImage.data.attributes.formats.thumbnail.width,
            height: article.attributes.CoverImage.data.attributes.formats.thumbnail.height
          }
        : null,
    thumbnailLarge:
      article.attributes.CoverImage && article.attributes.CoverImage.data
        ? {
            url: article.attributes.CoverImage.data.attributes.formats.small.url,
            width: article.attributes.CoverImage.data.attributes.formats.small.width,
            height: article.attributes.CoverImage.data.attributes.formats.small.height
          }
        : null
  }))
}

export function toS3AccelerateUrl(url: string): string {
  url = url.replace('s3.ap-southeast-1', 's3-accelerate')
  return url
}
